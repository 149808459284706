<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm1">
          <c-card class="cardClassDetailForm" title="오더내역">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="equipment-daily-appr-btn"
                  apprRequestTitle="작업결과 승인요청"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn label="삭제" v-if="editable && param.workPlanId && param.woWorkPlanStepCd=='WWPSC99998' && !disabled" icon="delete_forever" @btnClicked="removePlan" />
                <c-btn
                  v-if="editable &&!disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="정비오더No."
                  name="workOrderNo"
                  v-model="data.workOrderNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled"
                  label="정비오더명"
                  name="workResultName"
                  v-model="data.workResultName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  :required="true"
                  :editable="false"
                  codeGroupCd="WO_WORK_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="woWorkTypeCd"
                  label="정비유형"
                  v-model="data.woWorkTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  :required="true"
                  :editable="false"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-equip
                  :required="true"
                  :editable="false"
                  label="대표설비Tag"
                  name="equipmentCd"
                  v-model="data.equipmentCd"
                  @dataChange="equipChange">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-func-location 
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="false"
                  label="기능위치" 
                  name="funcLocationCd" 
                  v-model="data.funcLocationCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept type="edit" :required="true" :editable="false" label="정비오더 발행부서" name="workDeptCd" v-model="data.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-field
                  :required="true"
                  :editable="false"
                  :data="data"
                  type="user"
                  label="작업계획자"
                  name="planUserId"
                  v-model="data.planUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-work-order
                  :editable="false"
                  label="선행 정비오더" 
                  name="preWorkPlanId" 
                  v-model="data.preWorkPlanId"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept-multi
                  :editable="false"
                  :isArray="false"
                  label="정비작업팀" 
                  name="workDepts" 
                  v-model="data.workDepts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :editable="false"
                  :range="true"
                  type="date"
                  label="작업계획기간"
                  name="planDts"
                  v-model="data.planDts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :editable="editable&&!disabled"
                  type="date"
                  label="실제작업기간"
                  name="resultDts"
                  v-model="data.resultDts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable&&!disabled"
                  :rows="4"
                  label="정비요청 세부사항"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="gridCost"
                  title="비용정산 규칙"
                  :columns="gridCost.columns"
                  :data="data.costList"
                  :gridHeight="gridCost.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="costCenterCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowCost" v-if="editable&&!disabled && Boolean(data.workResultId)" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowCost" v-if="editable&&!disabled && Boolean(data.workResultId)" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="grid0"
                  title="관련설비"
                  :columns="grid0.columns"
                  :data="grid0.data"
                  :gridHeight="grid0.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="equipmentCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowEquip" v-if="editable&&!disabled && Boolean(data.workResultId)" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowEquip" v-if="editable&&!disabled && Boolean(data.workResultId)" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="gridWork"
          title="작업계획 목록"
          :columns="gridWork.columns"
          :data="gridWork.data"
          :gridHeight="gridWork.height"
          :editable="editable&&!disabled"
          bgHeaderClass="bg-grid-header-main"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :gridHeightAuto="true"
          :columnSetting="false"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm2">
          <c-card class="cardClassDetailForm" title="작업계획 상세">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn label="일일작업계획" icon="manage_search" @btnClicked="historyRowWork" v-if="editable && Boolean(data.workResultId)&&selectedWork" />
                <c-btn label="작업계획 추가" icon="add" @btnClicked="addRowWork" v-if="editable&&!disabled && Boolean(data.workResultId)" />
                <c-btn label="작업계획 삭제" icon="remove" @btnClicked="removeRowWork" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork&&workUpdateMode" />
                <c-btn
                v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"
                  :url="saveWorkUrl"
                  :isSubmit="isSave2"
                  :param="workdata"
                  :mappingType="mappingType2"
                  label="작업계획 저장"
                  icon="save"
                  @beforeAction="saveWork"
                  @btnCallback="saveCallbackWork" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled&&selectedWork"
                  label="항목번호"
                  name="sortOrder"
                  type="number"
                  v-model="workdata.sortOrder">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled&&selectedWork"
                  label="작업명"
                  name="workResultWorkName"
                  v-model="workdata.workResultWorkName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable&&!disabled&&selectedWork"
                  codeGroupCd="WO_OPR_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workOprTypeCd"
                  label="작업제어구분"
                  v-model="workdata.workOprTypeCd"
                  @datachange="changeWorkOprType"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :editable="editable&&!disabled&&selectedWork"
                  type="date"
                  label="작업기간"
                  name="workDts"
                  v-model="workdata.workDts"
                  @datachange="setWorkerPeriod"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                <c-checkbox
                  :editable="editable&&!disabled&&selectedWork"
                  :isFlag="true"
                  label="휴무일포함"
                  name="workHolidayFlag"
                  v-model="workdata.workHolidayFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-checkbox
                  :isArray="false"
                  :editable="editable&&!disabled&&selectedWork"
                  codeGroupCd="SOP_WORK_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="작업허가서 구분"
                  name="workpermitTypes"
                  v-model="workdata.workpermitTypes"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-checkbox
                  :isArray="false"
                  :editable="editable&&!disabled&&selectedWork"
                  codeGroupCd="WO_RISK_FACTOR"
                  itemText="codeName"
                  itemValue="code"
                  label="위험요인"
                  name="workRiskFactors"
                  v-model="workdata.workRiskFactors"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workdata.workOprTypeCd !== 'WOTC000001'">
                <c-vendor
                  :editable="editable&&!disabled&&selectedWork"
                  label="작업업체"
                  name="vendorCd"
                  v-model="workdata.vendorCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workdata.workOprTypeCd === 'WOTC000001'">
                <c-dept type="edit" :isFirstValue="workUpdateMode" :editable="editable&&!disabled&&selectedWork" label="작업부서" name="workDeptCd" v-model="workdata.workDeptCd" />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid"
          title="작업계획별 소요자재"
          :columns="grid.columns"
          :data="workdata.materialList"
          :gridHeight="grid.height"
          :editable="editable&&!disabled&&selectedWork"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="materialCd"
          @table-data-change="tableDataChangeMaterial"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="설비BOM 추가" icon="add" @btnClicked="addRowBom" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork" />
              <c-btn label="자재마스터 추가" icon="add" @btnClicked="addRow" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"/>
              <c-btn label="제외" icon="remove" @btnClicked="removeRow" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid2"
          title="작업계획별 서비스(외주)"
          :columns="grid2.columns"
          :data="workdata.serviceList"
          :gridHeight="grid2.height"
          :editable="editable&&!disabled&&selectedWork"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="vendorCd"
          @table-data-change="tableDataChangeService"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addRowService" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"/>
              <c-btn label="제외" icon="remove" @btnClicked="removeRowService" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="toolGrid"
          title="공기구"
          :columns="toolGrid.columns"
          :data="workdata.toolList"
          :gridHeight="toolGrid.height"
          :editable="editable&&selectedWork"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="toolId"
          @table-data-change="tableDataChangeTool"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addRowTool" v-if="editable&&!disabled &&selectedWork"/>
              <c-btn label="제외" icon="remove" @btnClicked="removeRowTool" v-if="editable&&!disabled &&selectedWork"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="spareGrid"
          title="예비품"
          :columns="spareGrid.columns"
          :data="workdata.spareList"
          :gridHeight="spareGrid.height"
          :editable="editable&&selectedWork"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="toolId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addRowSpare" v-if="editable&&!disabled &&selectedWork"/>
              <c-btn label="제외" icon="remove" @btnClicked="removeRowSpare" v-if="editable&&!disabled &&selectedWork"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="workerGrid"
          title="작업자 할당시간"
          :columns="workerGrid.columns"
          :data="workdata.workerList"
          :gridHeight="workerGrid.height"
          :editable="editable&&selectedWork"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="mdmSwsWorkerId"
          @table-data-change="tableDataChangeWorker"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="선택" icon="add" @btnClicked="addRowWorker" v-if="editable&&!disabled&&selectedWork"/>
              <c-btn label="직접추가" icon="add" @btnClicked="addRowWorker2" v-if="editable&&!disabled&&selectedWork"/>
              <c-btn label="제외" icon="remove" @btnClicked="removeRowWorker" v-if="editable&&!disabled&&selectedWork"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="padding-top:25px !important;"> 
        <c-upload 
          :attachInfo="attachInfo"
          :editable="editable && !disabled&&selectedWork"
          label="도면정보">
        </c-upload>
      </div> -->
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan-direction',
  props: {
    param: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        workResultId: '',
        targetEquipments: [],
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      selectedWork: false,
      workerSwss: [],
      workerSwssInfo: '',
      editable: true,
      detailUrl: '',
      updateUrl: '',
      equiplistUrl: '',
      insertequipUrl: '',
      removeequipUrl: '',
      costlistUrl: '',
      insertcostUrl: '',
      removecostUrl: '',
      worklistUrl: '',
      workgetUrl: '',
      saveWorkUrl: transactionConfig.wod.workorder.result.work.insert.url,
      removeworkUrl: '',
      isApproval: false,
      saveUrl: transactionConfig.wod.workorder.result.update.url,
      mappingType: 'PUT',
      mappingType2: 'PUT',
      workUpdateMode: false,
      isSave: false,
      isSave2: false,
      selectedWorkResultWorkId: '',
      data: {
        plantCd: null,  // 사업장코드
        preWorkPlanId: '',  // 작업계획 일련번호
        workPlanId: '',  // 작업계획 일련번호
        workResultId: '',  // 작업결과 일련번호
        workOrderNo: '',  // 작업지시번호
        workResultName: '',  // 작업명
        workDeptCd: '',  // 작업부서
        planUserId: '',  // 작업지시자
        woRequestId: '',  // 정비요청 일련번호
        reqDeptCd: '',  // 요청부서
        reqUserId: '',  // 요청인
        reqDt: '',  // 요청서 발행일
        woWorkTypeCd: null,  // 작업 유형코드
        equipmentCd: '',  // 대상 설비
        funcLocationCd: '',  // 기능위치
        remark: '',  // 정비요청 세부사항
        swpFlag: 'N',  // 안전작업허가서 발행여부
        woWorkPlanStepCd: '',  // 작업진행 상태
        preWoWorkPlanStepCd: '',
        planDt: '',
        planDts: [],
        planStartDt: '',  // 목표 시작일
        planStartTime: '',  // 목표 시작시간
        planStartDts: '',
        planEndDt: '',  // 목표 완료일
        planEndTime: '',  // 목표 종료시간
        planEndDts: '',
        resultDts: [],
        resultStartDt: '',  // 목표 시작일
        resultEndDt: '',  // 목표 완료일
        chgUserName: '',
        chgDtStr: '',
        costList: [],
      },
      wopdata: {
        sortOrder: '',
        workOprTypeCd: null,
        workDts: [],
        contents: '',
        workpermitCds: [],
        workRiskFactors: [],
        vendorCd: '',
        workUsers: [],
        workerCnt: '',
        permitNo: '',
      },
      workdata: {
        workResultId: '',  // 정비오더 일련번호
        workResultWorkId: '',  // 작업계획 일련번호
        workResultWorkName: '',  // 작업내용
        sortOrder: '',  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        workDeptCd: '',  // 작업부서
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        spareList: [],
        deleteSpareList: [],
        workerList: [],
        deleteWorkerList: [],
        workHolidayFlag: 'N',
      },
      grid0: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentNum',
            field: 'equipmentNum',
            label: '설비관리번호',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'center',
            // style: 'width:200px',
            sortable: false,
          },
          // {
          //   name: 'funcLocationName',
          //   field: 'funcLocationName',
          //   label: '기능위치',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '250px',
      },
      gridWork: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'workResultWorkName',
            field: 'workResultWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workDtsStr',
            field: 'workDtsStr',
            label: '작업기간',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },{
            name: 'workDeptName',
            field: 'workDeptName',
            label: '작업부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workpermitTypeNames',
            field: 'workpermitTypeNames',
            label: '작업허가서구분',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'materialNames',
            field: 'materialNames',
            label: '소요자재',
            align: 'center',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'vendorNames',
            field: 'vendorNames',
            label: '서비스 (외주)',
            align: 'center',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '336px',
      },
      gridCost: {
        columns: [
          {
            name: 'costCenterNo',
            field: 'costCenterNo',
            label: '코스트센터 번호',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터 명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'calculatePercent',
            field: 'calculatePercent',
            label: '정산율(%)',
            align: 'center',
            type: 'number',
            style: 'width:90px',
            sortable: false,
          },
        ],
        data: [],
        height: '250px',
      },
      grid: {
        columns: [
          {
            name: 'materialNo',
            field: 'materialNo',
            label: '자재번호',
            align: 'center',
            required: true,
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재',
            align: 'center',
            required: true,
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'materialDesc',
            field: 'materialDesc',
            label: '용도',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'unitPrice',
            field: 'unitPrice',
            label: '단가',
            type: 'number',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'unitEa',
            field: 'unitEa',
            label: '수량',
            type: 'number',
            required: true,
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '소계',
            type: 'cost',
            style: 'width: 150px',
            sortable: false,
          },
        ],
        data: [],
        height: '200px',
      },
      grid2: {
        columns: [],
        data: [],
        height: '200px',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WORK_ORDER_SERVICE_RESULT',
        taskKey: '',
      },
      toolGrid: {
        columns: [
          {
            name: 'toolName',
            field: 'toolName',
            label: '공기구명',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'toolStandard',
            field: 'toolStandard',
            label: '규격',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'toolCnt',
            field: 'toolCnt',
            label: '사용개수',
            type: 'number',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'toolPeriod',
            field: 'toolPeriod',
            label: '사용기간',
            style: 'width:200px',
            align: 'center',
            type: 'date',
            range: true,
            sortable: false,
          }
        ],
        data: [],
        height: '220px',
      },
      workerGrid: {
        columns: [
        ],
        data: [],
        height: '220px',
      },
      spareGrid: {
        columns: [
          {
            name: 'spareName',
            field: 'spareName',
            label: '예비품',
            align: 'left',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'spareCnt',
            field: 'spareCnt',
            label: '개수',
            type: 'number',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '220px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    userInfo() {
      return {
        userItems: this.wopdata.workUsers,
        userText: 'workUserName',
        userValue: 'workUserId',
        deptText: 'workDeptName',
        deptValue: 'workDeptCd',
      }
    },
    disabled() {
      this.$emit('disabledSet', this.disabledObj.disabled)
      return this.disabledObj.disabled;
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.preExistDisabled && this.data.woWorkPlanStepCd && this.data.woWorkPlanStepCd !== 'WWPSC00009' && this.data.woWorkPlanStepCd !== 'WWPSC99999' && this.data.woWorkPlanStepCd !== 'WWPSC99998' && !this.disabledAppr, // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000006', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          workPlanId: this.param.workPlanId
        },
        approvalRequestName: '작업결과 ['+this.data.workResultName+']', // 결재요청명 (문서 title)
        approvalConnId: this.param.workPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
    preExistDisabled() {
      // if (!this.data.preWoWorkPlanStepCd) {
      //   return true;
      // } else {
      //   return this.data.preWoWorkPlanStepCd == 'WWPSC00009';
      // }
      return true;
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workorder.result.get.url;
      this.updateUrl = transactionConfig.wod.workorder.result.update.url;
      this.equiplistUrl = selectConfig.wod.workorder.result.equip.url;
      this.insertequipUrl = transactionConfig.wod.workorder.result.equip.insert.url;
      this.removeequipUrl = transactionConfig.wod.workorder.result.equip.delete.url;
      this.costlistUrl = selectConfig.wod.workorder.result.cost.url;
      this.insertcostUrl = transactionConfig.wod.workorder.result.cost.save.url;
      this.removecostUrl = transactionConfig.wod.workorder.result.cost.delete.url;
      
      this.worklistUrl = selectConfig.wod.workorder.result.work.list.url;
      this.workgetUrl = selectConfig.wod.workorder.result.work.get.url;
      this.saveworkUrl = transactionConfig.wod.workorder.result.work.insert.url;
      this.removeworkUrl = transactionConfig.wod.workorder.result.work.delete.url;

      if (this.param.woWorkPlanStepCd !== 'WWPSC00003') {
        this.disabledObj.disabled = true;
      }
      this.getDetail();
      this.getColumn();
    },
    getDetail() {
      if (this.param.workPlanId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {workPlanId: this.param.workPlanId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (this.data.woWorkPlanStepCd !== 'WWPSC00003') {
            this.disabledObj.disabled = true;
          } else {
            this.disabledObj.disabled = false;
          }
          this.getEquipList();
          this.getWorks();
        },);
      } else {
        this.data.planUserId = this.$store.getters.user.userId;

        if (this.param.newEquipmentCd) {
          this.data.equipmentCd = this.param.newEquipmentCd;
          this.data.plantCd = this.param.plantCd;
          this.data.woWorkTypeCd = this.param.newWoWorkTypeCd;
          this.data.workResultnName = this.param.newTitle;
        }
      }
    },
    getEquipList() {
      if (this.data.workResultId) {
        this.$http.url = this.equiplistUrl;
        this.$http.param = {workResultId: this.data.workResultId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid0.data = _result.data;
          this.$emit('saveWorkResult', this.gridWork.data, this.grid0.data);
          this.$emit('getEquips', _result.data)
        },);
      }
    },
    setWorkerPeriod() {
      if (this.workdata.workerList && this.workdata.workerList.length > 0) {
        this.$_.forEach(this.workdata.workerList, _item => {
          _item.dailyList = [];
        })
      }
      this.setWorkerColumns();
    },
    setWorkerColumns() {
      if (!this.workdata.workDts || this.workdata.workDts.length == 0) {
        this.workerGrid.columns = [];
      }
      let _columns = [
        {
          name: 'workerName',
          field: 'workerName',
          label: '작업자',
          style: 'width:150px',
          align: 'left',
          type: 'text',
          sortable: false,
        },
        {
          name: 'skillName',
          field: 'skillName',
          label: '기술수준',
          style: 'width:300px',
          type: 'text',
          align: 'left',
          sortable: false,
        },
      ]
      let dayCnt = this.$comm.getDatediff(this.$comm.moment(this.workdata.workDts[0]), this.$comm.moment(this.workdata.workDts[1]))
      for (let i=0; i<=dayCnt; i++) {
        let date = this.$comm.getCalculatedDate(this.$comm.moment(this.workdata.workDts[0]), i+'d', 'YYYY-MM-DD')
        let month = date.substring(5);
         
        _columns.push({
          name: date,
          field: date,
          label: month,
          style: 'width:60px',
          align: 'right',
          type: 'number',
          sortable: false,
        },)
      }
      this.workerGrid.columns = _columns;
    },
    getColumn() {
      this.$comm.getComboItems('WO_COST_FACOTR_CD').then(_result => {
        this.grid2.columns = [
        {
            required: true,
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            required: true,
            name: 'workDt',
            field: 'workDt',
            label: '작업일',
            align: 'center',
            type: 'date',
            style: 'width: 140px',
            sortable: false,
          },
          {
            name: 'costFactorCd',
            field: 'costFactorCd',
            label: '원가요소',
            align: 'center',
            sortable: false,
            style: 'width: 200px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'manCnt',
            field: 'manCnt',
            label: '작업인원',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '인력단가',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'manCost',
            field: 'manCost',
            label: '인건비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialCost',
            field: 'materialCost',
            label: '자재비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'sumCost',
            field: 'sumCost',
            label: '합계',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
        ]
      });
    },
    getWorks() {
      if (this.data.workResultId) {
        this.$http.url = this.worklistUrl;
        this.$http.param = {workResultId: this.data.workResultId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.selectedWork = false;
          this.selectedWorkResultWorkId = '';
          this.workUpdateMode = false;
          this.gridWork.data = _result.data;
          this.workdata = {
            workHolidayFlag: 'N',
          };
        },);
      }
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveDataAppr() {
      let totalPercent = 0;
      this.$_.forEach(this.data.costList, _item => {
        totalPercent += parseInt(_item.calculatePercent)
      });
      if (totalPercent != 100) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '정산율의 총 합이 100이어야 합니다.', /* 작업계획취소가 처리되었습니다. */
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$refs['editForm1'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                let saveDataCost = this.data.costList.filter( x => {
                  return x.editFlag == 'C' || x.editFlag == 'U'
                });
                this.$_.forEach(saveDataCost, item => {
                  item.regUserId = this.$store.getters.user.userId;
                  item.chgUserId = this.$store.getters.user.userId;
                });
                this.data.costList = saveDataCost;
                let promises = [
                  {
                    func: this.approvalValue
                  },
                ];
                this.$comm.orderedPromise(promises);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    historyRowWork() {
      this.popupOptions.title = '일일작업계획 현황'; // 일일작업계획 현황
      this.popupOptions.param = {
        workResultId : this.data.workResultId,
        workResultWorkId : this.workdata.workResultWorkId,
        workOprTypeCd: this.workdata.workOprTypeCd,
        detailFlag: 'Y',
        workName: '작업명 : ' + this.workdata.workResultWorkName +' (' + this.workdata.workStartDt + '~' + this.workdata.workEndDt + ')'
      };
      this.popupOptions.target = () => import(`${"@/pages/wod/plan/workOrderDailyPlanByWork.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHistoryRowWork;
    },
    closeHistoryRowWork() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveData() {
      if (this.param.workPlanId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      }
      let totalPercent = 0;
      this.$_.forEach(this.data.costList, _item => {
        totalPercent += parseInt(_item.calculatePercent)
      });
      if (totalPercent != 100) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '정산율의 총 합이 100이어야 합니다.', /* 작업계획취소가 처리되었습니다. */
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$refs['editForm1'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                let saveDataCost = this.data.costList.filter( x => {
                  return x.editFlag == 'C' || x.editFlag == 'U'
                });
                this.$_.forEach(saveDataCost, item => {
                  item.regUserId = this.$store.getters.user.userId;
                  item.chgUserId = this.$store.getters.user.userId;
                });
                this.data.costList = saveDataCost;
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    addRowEquip() {
      this.popupOptions.title = '설비 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowEquip;
    },
    closeaddRowEquip(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let _saveData = [];
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid0.data, { equipmentCd: item.equipmentCd }) === -1) {
            _saveData.push({
              workResultId: this.data.workResultId,  // 작업지시번호
              equipmentNo: item.equipmentNo,  // 작업자 사번
              equipmentCd: item.equipmentCd,
              equipmentNum: item.equipmentNum,
              equipmentName: item.equipmentName,
              funcLocationName: item.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              mainEquipFlag: 'N',
              editFlag: 'C',
            })
          }
        });
        this.$http.url = this.insertequipUrl;
        this.$http.type = 'POST';
        this.$http.param = _saveData;
        this.$http.request(() => {
          this.getEquipList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeRowEquip() {
      let selectData = this.$refs['grid0'].selected;
      
      let filterData = this.$_.filter(selectData, item => {
        return item.mainEquipFlag === 'Y'
      })
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else if (filterData && filterData.length > 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '대표설비는 삭제할 수 없습니다.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removeequipUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid0.data = this.$_.reject(this.grid0.data, item);
              })
            this.$emit('saveWorkResult', this.gridWork.data, this.grid0.data);
              this.$refs['grid0'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    equipChange(data) {
      // 기능위치 변경
      if (data.funcLocationCd !== null) {
        if (this.data.funcLocationCd === null) {
          this.data.funcLocationCd = data.funcLocationCd
        } else {
          if (this.data.funcLocationCd != data.funcLocationCd) {
            this.data.funcLocationCd = data.funcLocationCd
          }
        }
      }
      if (data) {
        if (this.$_.findIndex(this.grid0.data, { equipmentCd: data.equipmentCd }) > -1) {
          this.$http.url = this.removeequipUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values([{
              workResultId: this.data.workResultId,  // 작업지시번호
              equipmentNo: data.equipmentNo,
              equipmentCd: data.equipmentCd,
              equipmentName: data.equipmentName,
              funcLocationName: data.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
            }])
          };
          this.$http.request(() => {
            this.$refs['grid0'].$refs['compo-table'].clearSelection();
            this.saveMainEquip(data)
          },);
        } else {
          this.saveMainEquip(data);
        }
      }
    },
    saveMainEquip(data) {
      this.$http.url = this.insertequipUrl;
      this.$http.type = 'POST';
      this.$http.param = [{
        workResultId: this.data.workResultId,  // 작업지시번호
        equipmentNo: data.equipmentNo,
        equipmentCd: data.equipmentCd,
        equipmentName: data.equipmentName,
        funcLocationName: data.funcLocationName,
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        mainEquipFlag: 'Y',
        editFlag: 'C',
      }];
      this.$http.request(() => {
        this.getEquipList();
      });
    },
    // 작업허가서 선택
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.wopdata.plantCd, 
        popupMode: 'Y',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length > 0) {
        this.workdata.sopWorkPermitId = data[0].permitNo;
      }
    },
    removeWorkPermit() {
      this.workdata.sopWorkPermitId = '';
    },
    addWorkPermit() {
      // 작업허가서
      this.popupOptions.title = '작업허가서 발행';
      this.popupOptions.param = {
        plantCd: this.wopdata.plantCd,
        workPlanId: this.param.workPlanId,
      };
      this.popupOptions.isFull = true;
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupSwp;
    },
    closePopupSwp() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    rowClick(row) {
      this.selectedWork = true;
      this.workUpdateMode = true;
       if (row.workResultWorkId) {
        this.$set(this.attachInfo, 'taskKey', row.workResultWorkId)
      }
      this.selectedWorkResultWorkId = row.workResultWorkId;
      // 상세조회
      this.$http.url = this.workgetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        workResultId : this.data.workResultId,
        workResultWorkId : row.workResultWorkId,
      }
      this.$http.request((_result) => {
        this.workdata = _result.data;
        if (this.workdata.workerList && this.workdata.workerList.length > 0) {
          this.$_.forEach(this.workdata.workerList, _item => {
            if (_item.dailyList && _item.dailyList.length > 0) {
              this.$_.forEach(_item.dailyList, __item => {
                _item[__item.workDate] = __item.workTime
              })
            }
          })
        }
        if (this.workdata.toolList && this.workdata.toolList.length > 0) {
          this.$_.forEach(this.workdata.toolList, _item => {
            this.$set(_item, 'toolPeriod', [_item.toolStartDt, _item.toolEndDt])
          })
        }

        this.setWorkerColumns();
      },
      () => {
      });
    },
    addRowWork() {
      this.selectedWork = true;
      this.selectedWorkResultWorkId = '';
      this.workUpdateMode = false;
      this.workdata = {
        workResultId: this.data.workResultId,  // 정비오더 일련번호
        workResultWorkId: uid(),  // 작업계획 일련번호
        workResultWorkName: '',  // 작업내용
        sortOrder: (this.gridWork.data.length + 1) * 10,  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workDts: [],  // 작업기간
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        workerList: [],
        deleteWorkerList: [],
        spareList: [],
        deleteSpareList: [],
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
      }
    },
    removeRowWork() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업계획을 삭제하시겠습니까?',
        // TODO : 작업계획의 모든 정보가 삭제됩니다.\r\n작업계획을 삭제하시겠습니까?
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.removeworkUrl, this.workdata.workResultWorkId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '작업계획이 삭제되었습니다.', /* 작업계획취소가 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.getWorks();
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveWork() {
      if (this.selectedWork && this.workUpdateMode) {
        this.saveWorkUrl = transactionConfig.wod.workorder.result.work.update.url;
        this.mappingType2 = 'PUT';
      } else {
        this.saveWorkUrl = transactionConfig.wod.workorder.result.work.insert.url;
        this.mappingType2 = 'POST';
      }
      this.$refs['editForm2'].validate().then(_result => {
        if (this.$comm.validTable(this.grid.columns, this.workdata.materialList)) {
          if (this.$comm.validTable(this.grid2.columns, this.workdata.serviceList)) {
            if (_result) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.workdata.regUserId = this.$store.getters.user.userId;
                  this.workdata.chgUserId = this.$store.getters.user.userId;
                  this.isSave2 = !this.isSave2;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('APP_VALID_ERROR');
            }
          }
        }
      });
    },
    saveCallbackWork(_result) {
      this.workdata.workResultWorkId = _result.data;
      this.$http.url = this.worklistUrl;
      this.$http.param = {workResultId: this.data.workResultId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridWork.data = _result.data;
        this.rowClick(this.workdata);
      },);
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    addRowCost() {
      this.popupOptions.title = '코스트센터 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
        maintenanceFlag: '',
        purchaseFlag: '',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/cc/costCenterPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowCost;
    },
    closeaddRowCost(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.costList, { costCenterCd: item.costCenterCd }) === -1) {
            this.data.costList.push({
              workPlanId: this.param.workPlanId,  // 작업계획 일련번호
              workResultId: this.data.workResultId,  // 작업계획 일련번호
              costCenterCd: item.costCenterCd,  // 코스트센터 코드
              costCenterNo: item.costCenterNo,  // 코스트센터 코드
              costCenterName: item.costCenterName,  // 코스트센터 코드
              calculatePercent: (this.data.costList.length == 0 && data.length == 1 ? '100' : ''),  // 정산율
              calculateCost: '',  // 정산금액
              remark: '',  // 비고
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowCost() {
      let selectData = this.$refs['gridCost'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removecostUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.costList = this.$_.reject(this.data.costList, item);
              })
              this.$refs['gridCost'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    changeWorkOprType() {
      if (this.workdata.workOprTypeCd === 'WOTC000001') { // 자체작업
        this.workdata.vendorCd = '';
        this.workdata.vendorWorkerCnt = null;
      } else {
        this.workdata.workDeptCd = '';
        this.workdata.workUsers = [];
      }
    },
    addRow() {
      this.popupOptions.title = '자재마스터 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRow;
    },
    closeaddRow(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workdata.materialList, { materialCd: item.materialCd }) === -1) {
            this.workdata.materialList.push({
              workResultId: this.data.workResultId,  // 작업지시번호
              workResultWorkId: this.workdata.workResultWorkId,
              materialCd: item.materialCd,  // 작업자재 일련번호
              materialNo: item.materialNo,  // 작업자재 일련번호
              materialName: item.materialName,  // 작업자재
              materialDesc: '',  // 작업자재 용도
              unitPrice: item.price,  // 단가
              unitEa: '',  // 수량
              priceCost: '',  // 소계
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workdata.deleteMaterialList) this.workdata.deleteMaterialList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workdata.deleteMaterialList, { materialCd: item.materialCd }) === -1) {
            this.workdata.deleteMaterialList.push(item)
          }
          this.workdata.materialList = this.$_.reject(this.workdata.materialList, item);
        })
        this.$refs['grid'].$refs['compo-table'].clearSelection();
      }
    },
    addRowBom() {
      this.popupOptions.title = '설비별 BOM 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple',
        equipmentCds: this.equips,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/bomPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowBom;
    },
    closeaddRowBom(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workdata.materialList, { materialCd: item.equipmentBomConnCd }) === -1) {
            this.workdata.materialList.push({
              workResultId: this.data.workResultId,  // 작업지시번호
              workResultWorkId: this.workdata.workResultWorkId,
              materialCd: item.equipmentBomConnCd,  // 작업자재 일련번호
              materialNo: item.materialNo,  // 작업자재 일련번호
              materialName: item.equipmentBomName,  // 작업자재
              materialDesc: '',  // 작업자재 용도
              unitPrice: item.price,  // 단가
              unitEa: '',  // 수량
              priceCost: '',  // 소계
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    addRowService() {
      this.popupOptions.title = '작업업체 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowService;
    },
    closeaddRowService(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workdata.serviceList, { vendorCd: item.vendorCd }) === -1) {
            this.workdata.serviceList.push({
              workResultId: this.data.workResultId,  // 작업지시번호
              workResultWorkId: this.workdata.workResultWorkId,
              vendorCd: item.vendorCd,
              vendorName: item.vendorName,
              workDate: '',
              unitEa: '',
              priceCost: '',
              costFactorCd: '',
              manCost: '',
              materialCost: '',
              sumCost: '',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowService() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workdata.deleteServiceList) this.workdata.deleteServiceList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workdata.deleteServiceList, { vendorCd: item.vendorCd }) === -1) {
            this.workdata.deleteServiceList.push(item)
          }
          this.workdata.serviceList = this.$_.reject(this.workdata.serviceList, item);
        })
        this.$refs['grid2'].$refs['compo-table'].clearSelection();
      }
    },
    
    addRowTool() {
      this.popupOptions.title = '공기구 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.workdata.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/tl/toolPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRowTool;
    },
    closeRowTool(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workdata.toolList, { toolId: item.toolId }) === -1) {
            this.workdata.toolList.push({
              workResultId: this.data.workResultId,
              workResultWorkId: this.workdata.workResultWorkId,
              toolId: item.toolId,
              toolName: item.toolName,
              toolStandard: item.toolStandard,
              toolCnt: 0,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowTool() {
      let selectData = this.$refs['toolGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workdata.deleteToolList) this.workdata.deleteToolList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workdata.deleteToolList, { materialCd: item.materialCd }) === -1) {
            this.workdata.deleteToolList.push(item)
          }
          this.workdata.toolList = this.$_.reject(this.workdata.toolList, item);
        })
        this.$refs['toolGrid'].$refs['compo-table'].clearSelection();
      }
    },
    addRowSpare() {
      this.workdata.spareList.push({
        workResultId: this.data.workResultId,
        workResultWorkId: this.workdata.workResultWorkId,
        workPlanSpareId: uid(),
        spareName: '',
        spareCnt: '',
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
      },
    removeRowSpare() {
      let selectData = this.$refs['spareGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workdata.deleteSpareList) this.workdata.deleteSpareList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workdata.deleteSpareList, { workPlanSpareId: item.workPlanSpareId }) === -1) {
            this.workdata.deleteSpareList.push(item)
          }
          this.workdata.spareList = this.$_.reject(this.workdata.spareList, item);
        })
        this.$refs['grid'].$refs['compo-table'].clearSelection();
      }
    },
    addRowWorker() {
      this.popupOptions.title = '작업자 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.workdata.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRowWorker;
    },
    closeRowWorker(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.workdata.workerList.push({
            workResultId: this.data.workResultId,
            workResultWorkId: this.workdata.workResultWorkId,
            mdmSwsWorkerId: uid(),
            skillName: item.skillName,
            workerName: item.userName,
            dailyList: [],
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            editFlag: 'C',
          })
        });
      }
    },
    addRowWorker2() {
      this.workdata.workerList.push({
        workResultId: this.data.workResultId,
        workResultWorkId: this.workdata.workResultWorkId,
        mdmSwsWorkerId: uid(),
        skillName: '',
        workerName: '',
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    removeRowWorker() {
      let selectData = this.$refs['workerGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workdata.deleteWorkerList) this.workdata.deleteWorkerList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workdata.deleteWorkerList, { mdmSwsWorkerId: item.mdmSwsWorkerId }) === -1) {
            this.workdata.deleteWorkerList.push(item)
          }
          this.workdata.workerList = this.$_.reject(this.workdata.workerList, item);
        })
        this.$refs['workerGrid'].$refs['compo-table'].clearSelection();
      }
    },
    tableDataChangeMaterial(props) {
      if (props.row['unitPrice'] > 0 && props.row['unitEa'] > 0) {
        this.$set(props.row, 'priceCost', parseInt(props.row['unitPrice']) * parseInt(props.row['unitEa']));
      } else {
        this.$set(props.row, 'priceCost', 0);
      }
    },
    tableDataChangeService(props, col) {
      if (col.name !== 'manCost' && col.name !== 'materialCost') {
        if (props.row['priceCost'] > 0 && props.row['manCnt'] > 0) {
          this.$set(props.row, 'manCost', parseInt(props.row['priceCost']) * parseInt(props.row['manCnt']));
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        } else {
          this.$set(props.row, 'manCost', 0);
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        }
      } else {
        this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost']));
      }
    },
    tableDataChangeWorker(props, col) {
      if (col.name !== 'workerName' && col.name !== 'skillName') {
        if (!props.row.dailyList) props.row.dailyList = [];

        props.row.dailyList = this.$_.reject(props.row.dailyList, {workDate: col.name});
        // if (this.$_.findIndex(props.row.dailyList, {workDate: col.name}) === -1) {
          props.row.dailyList.push({
            workPlanDailyId: uid(),
            workResultWorkId: this.workdata.workResultWorkId,
            workPlanWorkerId: props.row.workPlanWorkerId,
            workDate: col.name,
            workTime: col.value,
          })
        // } else {
        //   let idx =  props.row.dailyList[this.$_.findIndex(props.row.dailyList, {workDate: col.name})]
        //   props.row.dailyList[idx].workTime = col.value
        // }
      }
    },
    tableDataChangeTool(props, col) {
      if (col.name === 'toolPeriod' && props.row[col.name] && props.row[col.name].length > 0) {
        this.$set(props.row, 'toolStartDt', props.row[col.name][0] ? props.row[col.name][0] : '')
        this.$set(props.row, 'toolEndDt', props.row[col.name][1] ? props.row[col.name][1] : '')
      }
    }
  }
};
</script>